import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import {
  MenuIcon,
  HomeIcon,
  StarIcon,
  CurrencyDollarIcon,
  XIcon,
  BookOpenIcon
} from '@heroicons/react/outline';
// import {
//   FiArchive,
//   FiCreditCard,
//   FiRss
// } from "react-icons/fi";

// Logo
// import logo from '../images/adminmate-full-logo.svg';
import newLogo from "../images/logo-blue.svg";

// Data
import config from '../data/general';

const solutions = [
  {
    name: 'Home',
    // description: 'Get a better understanding of where your traffic is coming from.',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Features',
    // description: 'Speak directly to your customers in a more meaningful way.',
    href: '/#features',
    icon: StarIcon,
  },
  {
    name: 'Pricing',
    // description: "Connect with third-party tools that you're already using.",
    href: '/pricing',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Blog',
    // description: "Connect with third-party tools that you're already using.",
    href: '/blog/',
    icon: BookOpenIcon,
  }
];

export default function Menu() {
  const menuLinkClass = 'flex items-center text-base font-medium text-gray-900 px-3 py-2 rounded-md hover:bg-gray-100 transition duration-300 ease-in-out';

  return (
    <Popover className="relative z-30">
      {/* <div className="container mx-auto"> */}
        <div className="px-7 mx-auto max-w-7xl">
        {/* <div className="container mx-auto px-6"> */}
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex flex-shrink-0 justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <img src={newLogo} className="w-40 self-start" alt="Adminmate" />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-bluecolor">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-2">

              {/* <Link to="/" className={menuLinkClass}>
                Home
              </Link> */}

              <Link to="/#features" className={menuLinkClass}>
                Features
              </Link>

              <Link to="/pricing/" className={menuLinkClass}>
                Pricing
              </Link>

              <Link to="/blog/" className={menuLinkClass}>
                Blog
              </Link>

            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a href={config.login_link} className={`${menuLinkClass} whitespace-nowrap`}>
                Login
              </a>
              {/* px-3 py-2 bg-amcolor hover:bg-amcolor-darker rounded font-normal transition duration-500 ease-in-out cursor-pointer text-white */}
              <a
                href={config.signup_link}
                className="ml-6 whitespace-nowrap inline-flex items-center justify-center px-6 py-2 border border-transparent rounded-full text-base font-normal text-white transition duration-500 ease-in-out bg-amblue hover:opacity-90"
              >
                Get Started for Free
              </a>
            </div>
          </div>
        {/* </div> */}
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <a href="/" className="outline-none">
                    <img src={newLogo} className="h-8 w-auto" alt="Adminmate" />
                  </a>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amcolor">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-6">
                  {solutions.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      {/* <item.icon className="flex-shrink-0 h-6 w-6 text-amblue" aria-hidden="true" /> */}
                      <span className="text-base font-medium text-gray-900">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <a
                  href={config.signup_link}
                  className="w-full flex items-center justify-center px-4 py-3 border border-transparent rounded-md text-base font-medium text-white bg-amblue hover:opacity-90"
                >
                  Get started for free
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Already have an account?{' '}
                  <a href={config.login_link} className="text-amblue hover:opacity-90">
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
