import React from 'react';
import { Link } from 'gatsby';

// Data
import config from '../data/general';

// Images
// import logo from '../images/adminmate-full-logo.svg';
import newLogo from "../images/logo-blue.svg";

export default function Footer() {
	const linkStyle = 'text-gray-700 text-base hover:text-gray-900 transition duration-500 ease-in-out';
	const titleStyle = 'text-sm leading-5 font-semibold tracking-wider text-gray-700 uppercase';

	return (
		<div className="border-t">
			<div className="px-7 mx-auto max-w-7xl py-12 lg:py-16">
				<div className="xl:grid xl:grid-cols-3 xl:gap-8">
					<div className="mb-8 xl:mb-0">
						<h4 className="leading-5 font-semibold tracking-wider text-gray-300">
							<img src={newLogo} className="w-40" alt="Adminmate Logo" />
						</h4>
						<p className="mt-4 text-gray-700 text-base leading-7">
							Adminmate is <u>not VC backed</u>.<br />It is 100% bootstrapped by <b>Marc & Charles</b>.<br />We also run a Shopify App Studio powering more than <b>22,500 shops</b> across <b>85+ countries</b> and everything is handled by Adminmate on a daily basis. <span role="img">✌️</span>
							{/* Made and hosted in the EU <span role="img">🇪🇺</span><br />
							Adminmate is fully-bootstrapped project built by <a className="text-amblue hover:opacity-90 transition duration-500" href="https://www.linkedin.com/in/marcdelalonde" target="_blank" rel="noreferrer">Marc Delalonde</a> <span role="img">✌️</span> */}
						</p>
					</div>
					<div className="grid grid-cols-2 gap-8 md:grid-cols-3 col-span-2">
						<div>
							<h4 className={titleStyle}>Adminmate</h4>
							<ul className="mt-3">
								{/* <li>
									<a href="/" className={linkStyle}>Home</a>
								</li> */}
								<li>
									<Link to="/#features" className={linkStyle}>Features</Link>
								</li>
								<li className="mt-3">
									<Link to="/pricing/" className={linkStyle}>Pricing</Link>
								</li>
								<li className="mt-3">
									<a href={config.login_link} className={linkStyle}>Login</a>
								</li>
								<li className="mt-3">
									<a href={config.signup_link} className={linkStyle}>Get started</a>
								</li>
							</ul>
						</div>
						<div>
							<h4 className={titleStyle}>Community</h4>
							<ul className="mt-3">
								<li>
									<Link to="/blog" className={linkStyle}>Blog</Link>
								</li>
								<li className="mt-3">
									<a href="https://adminmate.instatus.com/" target="_blank" rel="noreferrer" className={linkStyle}>Status</a>
								</li>
								<li className="mt-3">
									<a href="https://docs.adminmate.io/" target="_blank" className={linkStyle}>Documentation</a>
								</li>
								<li className="mt-3">
									<a href="https://github.com/Adminmate" target="_blank" rel="noreferrer" className={linkStyle}>Github</a>
								</li>
								{/* <li className="mt-3">
									<a href="https://www.linkedin.com/company/adminmate" target="_blank" rel="noreferrer" className={linkStyle}>Linkedin</a>
								</li> */}
							</ul>
						</div>
						<div>
							<h4 className={titleStyle}>Company</h4>
							<ul className="mt-3">
								<li>
									<Link to="/about/" className={linkStyle}>About</Link>
								</li>
								{/* <li className="mt-3">
									<a href="/contact-us" className={linkStyle}>Contact</a>
								</li> */}
								<li className="mt-3">
									<Link to="/terms/" className={linkStyle}>Terms & Conditions</Link>
								</li>
								<li className="mt-3">
									<Link to="/privacy-policy/" className={linkStyle}>Privacy Policy</Link>
								</li>
								<li className="mt-3">
									<Link to="/cookie-policy/" className={linkStyle}>Cookie Policy</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};